import request from "@/axios";
/**
 * 招商会推荐列表
 */
export function getInvestmentTopList() {
  return request({
    url: "/web/investment/topList",
    method: "post",
  });
}
/**
 * 招商会 - 列表
 */
export function getConferenceList(params) {
  return request({
    url: "/web/investment/list",
    method: "post",
    params,
  });
}
/**
 * 招商会 - 详情
 */
export function getConferenceDetail(id) {
  return request({
    url: "/web/investment/get",
    method: "post",
    params: { id },
  });
}
/**
 * 招商会 - 报名结果
 */
export function geConferenceSignUpStatus(conferenceId) {
  return request({
    url: "/web/conference/isSignup",
    method: "post",
    params: { conferenceId },
  });
}
/**
 * 招商会 - 提交报名
 */
 export function submitConferenceSignUpForm(data) {
  return request({
    url: "/web/conference/signup",
    method: "post",
    data
  });
}
/**
 * 经营类目
 */
export function getBizformDictionary() {
  return request({
    url: "/web/common/catLevel",
    method: "post",
  });
}

/**
 * 经营类目  业态变更
 */
export function getBizformat() {
  return request({
    url: "/web/common/catLevel/nca",
    method: "post",
  });
}
/**
 * 发送短信验证码
 */
export function sendCodeMessage({ mobile }) {
  return request({
    url: "/web/merchant/sendMobileMessage",
    method: "post",
    params: { mobile },
  });
}
/**
 * 校验短信验证码
 */
 export function checkMobileCode(params) {
  return request({
    url: "/web/merchant/checkMobileCode",
    method: "post",
    params,
  });
}
/**
 * 入驻进度查询
 */
export function getJoinProgressStatus(params) {
  return request({
    url: "/web/merchant/progress",
    method: "post",
    params,
  });
}
/**
 * 审批异常原因
 */
export function getRejectMsg() {
  return request({
    url: "/web/merchant/rejectMsg",
    method: "post",
  });
}
/**
 * 入驻全量资料查询
 */
export function getJoinAllFormInfo() {
  let userId = Number(sessionStorage.getItem("user_id"));
  return request({
    url: "/web/merchant/get",
    method: "post",
    data: { userId },
  });
}
/**
 * 保存 - 前置信息
 */
export function submitPreInfoForm(data) {
  return request({
    url: "/web/merchant/front",
    method: "post",
    data,
  });
}
/**
 * 校验联系人手机号 - 公司联系人
 * @return  true-发送验证码成功 false-发送验证码不成功
 */
 export function getMobileMessage({ mobile }) {
  return request({
    url: '/web/merchant/getMobileMessage',
    method: 'post',
    params: { mobile }
  })
}
/**
 * 校验联系人手机号 - 公司联系人
 * @return 1-乐活秀已注册 2-乐活通已注册
 */
export function checkMobile() {
  return request({
    url: '/web/merchant/checkMobile',
    method: 'post'
  })
}
/**
 * 拉取乐活通资质
 */
 export function getMatchUserInfo({ mobile }) {
  return request({
    url: '/web/merchant/getMatchUserInfo',
    method: 'post',
    params: { mobile }
  })
}
/**
 * 保存 - 公司联系人
 */
export function submitContactInfoForm(data) {
  return request({
    url: "/web/merchant/contact",
    method: "post",
    data,
  });
}
/**
 * orc - 营业执照 & 法人证件
 */
export function sumbitOcrSrc(data) {
  return request({
    url: "/web/merchant/upload",
    method: "post",
    data,
  });
}
/**
 * ocr - 识别结果查询
 */
export function getOcrResultByType(type) {
  return request({
    url: "/web/merchant/getOcrResultByType",
    method: "post",
    params: { type },
  });
}
/**
 * 保存 - 企业信息
 */
export function submitCompanyInfoForm(data) {
  return request({
    url: "/web/merchant/company",
    method: "post",
    data,
  });
}
/**
 * 查询 - 品牌列表
 */
export function getBrandList(params) {
  return request({
    url: "/web/brand/list",
    method: "post",
    params,
  });
}
/**
 * 查询 - 品牌详情
 */
export function getBrandDetailById(brandId) {
  return request({
    url: "/web/brand/get",
    method: "post",
    params: { brandId },
  });
}
/**
 * 添加 - 新品牌
 */
export function submitNewBrandForm(data) {
  return request({
    url: "/web/brand/make",
    method: "post",
    data,
  });
}
/**
 * 保存 - 品牌资质
 */
export function submitBrandInfoForm(data) {
  return request({
    url: "/web/merchant/brand",
    method: "post",
    data,
  });
}
/**
 * 费用预估接口
 */
 export function estimateApi(params) {
  return request({
    url: "/web/common/estimate",
    method: "post",
    params,
  });
}

/**
 * 联系人列表
 */
 export function getContactList(params) {
  return request({
    url: "/web/investment/queryContactListByBizFormCode",
    method: "post",
    params,
  })
}
/**
 * 类目列表接口
 */
 export function caAllApi() {
  return request({
    url: "/web/common/caAll",
    method: "post"
  });
}
/**
 * 招商会列表
 */
 export function getTopListApi() {
  return request({
    url: "/web/investment/topList",
    method: "post"
  });
}
