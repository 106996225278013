<template>
	<div class="page-conference-list">
		<el-card v-if="queryParams.total > 0">
			<div class="list-box">
				<div class="list-item" v-for="c in conferenceList" :key="c.id" @click="jump2detail(c)">
					<div class="name" :class="{
            'will': c.conferenceState == 0,
            'doing': c.conferenceState == 1,
            'finished': c.conferenceState == 2}">{{c.conferenceTitle}}</div>
					<div class="detail">
						<div class="item date">时间：{{c._holdStartTime}}</div>
						<div class="item address">地址：{{c.holdLocation}}</div>
						<div class="item type">招商品类：{{c.conferenceTradeNames || '--'}}</div>
					</div>
					<div class="time" :class="{
            'active': c.conferenceState == 1
          }">{{c.timeStr}}</div>
				</div>
			</div>
			<div class="custom-action-box">
				<el-pagination @size-change="handlePageSizeChange" @current-change="handlePageCurrentChange"
					:page-sizes="[10, 20, 50]" :page-size="queryParams.size" :current-page="queryParams.current"
					layout="total, sizes, prev, pager, next, jumper" :total="queryParams.total">
				</el-pagination>
			</div>
		</el-card>
		<div v-else>
			<div class="no-data">
				<i class="el-icon-info"></i>
				<div class="text">暂无数据</div>
			</div>
		</div>
	</div>
</template>

<script>
import { getConferenceList } from "@/api/api-join-mall";
export default {
	data() {
		return {
			queryParams: {
				current: 1,
				size: 10,
				total: 0,
			},
			conferenceList: [],
		};
	},
	methods: {
		_formatDate2local(str, startIndex) {
			let dateArr = str.split(" ")[0].split("-"), dateUnit = ['年', '月', '日'], result = '';
			dateArr.forEach((el, index) => {
				if(index >= startIndex) {
					result += (Number(el) + dateUnit[index])
				}
			})
			return result;
		},
		getConferenceList() {
			getConferenceList(this.queryParams).then((res) => {
				if (res.data.code === 0) {
					let temp = res.data.data.records || [];
					if(temp.length > 0) {
						temp.forEach(el => {
							el.timeStr = this._formatDate2local(el.holdStartTime, 1);
							el._holdStartTime = this._formatDate2local(el.holdStartTime, 0);
						})
					}
					this.conferenceList = temp;
					this.queryParams.total = res.data.data.total;
				}
			});
		},
		handlePageSizeChange(val) {
			this.queryParams.size = val;
			this.getConferenceList();
		},
		handlePageCurrentChange(val) {
			this.queryParams.current = val;
			this.getConferenceList();
		},
		jump2detail({ id }) {
			this.$router.push({
				name: "ConferenceDetail",
				query: {
					id,
				},
			});
		},
	},
	created() {
		this.getConferenceList();
	},
};
</script>

<style lang="scss" scoped>
@import "../../mall/assets/styles/base.scss";
.page-conference-list {
	.list-box {
		padding: 48px 40px;
		.list-item {
			position: relative;
			font-size: 12px;
			color: #666666;
			padding: 47px 177px 33px;
			margin-bottom: 28px;
			border-radius: 10px;
			background-color: #fafafa;
			cursor: pointer;
			transition: box-shadow 0.3s linear;
			&:hover {
				box-shadow: 0px 10px 20px 0px #e9edf1;
			}
			&:last-of-type {
				margin-bottom: 0;
			}
			.name {
        position: relative;
        display: inline-block;
				font-size: 18px;
				color: #333333;
				margin-bottom: 26px;
				&::after {
					position: absolute;
					right: -90px;
					width: 70px;
					height: 24px;
					line-height: 24px;
					text-align: center;
					font-size: 14px;
					border-radius: 50px;
					color: #ffffff;
					background-color: #b0b0b0;
				}
				&.will::after {
					content: "未开始";
				}
				&.doing::after {
					content: "进行中";
					color: #333333;
					background-color: #fbd5a9;
				}
				&.finished::after {
					content: "已结束";
				}
			}
			.detail {
				.item {
					display: inline-block;
					margin-right: 40px;
				}
			}
			.time {
				position: absolute;
				top: 0;
				left: 0;
				bottom: 0;
				width: 120px;
				height: 38px;
				line-height: 38px;
				font-size: 18px;
				color: #ffffff;
				text-align: center;
				margin: auto 0;
				background-color: #b0b0b0;
				&.active {
					background: linear-gradient(to right, #ff4a2d, #ff8600);
				}
			}
		}
	}
	.custom-action-box {
		margin-top: 20px;
		margin-bottom: 60px;
	}
	.no-data {
		color: #CCCCCC;
		font-size: 20px;
		text-align: center;
		margin-top: 20vh;
		i {
			font-size: 30px;
			margin-bottom: 20px;
		}
	}
}
</style>